import { ServerConfig } from './config.interface';

const highlandSportConfig: ServerConfig = {
  id: 'highlandsport',
  code: 'havlickuv-brod-highland-sport',
  displayPartnerPages: true,
  cssTheme: 'highlandsport',
  modalTypeToSlugMapping: {
    'gdpr': 'zasady-ochrany-osobnich-udaju',
    'biometric': 'predani-biometrickych-udaju',
    'terms': 'obchodni-podminky'
  },
  imageToPreload: [],
  faviconList: [
    "<link rel='icon' type='image/x-icon' href='/assets/higland-favicon.ico' sizes='32x32'>"
  ]
};

export default highlandSportConfig;
